import React, { useState } from "react";
import {
  Modal,
  Header,
  Button,
  SpaceBetween,
  Input,
  Box,
} from "@amzn/awsui-components-react";
import { ValueWithLabel } from "./util";

const CloseIssuesModal = ({ visible, setVisible, issues }) => {
  const [comment, setComment] = useState("");

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={<Header>Close {issues.length} issue</Header>}
      size="medium"
    >
      <SpaceBetween size="m">
        <ValueWithLabel label={"Comment"}>
          <Input
            value={comment}
            placeholder="Add a comment"
            onChange={({ detail }) => setComment(detail.value)}
          ></Input>
        </ValueWithLabel>
        <Box textAlign="right">
          <Button variant="primary">Save</Button>
        </Box>
      </SpaceBetween>
    </Modal>
  );
};

export default CloseIssuesModal;
