import * as React from "react";
import {
    Flashbar
} from "@amzn/awsui-components-react";
import "./styles/portrait.css"
import "./styles/header.css"
import "./styles/file.css"
import Landing from "./pages/landing/landing";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAlias } from "./features/auth/userAliasSlice";
import { preLoad } from "./preLoad";


const AuthLoadingBar = () => {
    return <Flashbar
        items={[
            {
                type: "success",
                loading: true,
                content: "Authenticating and Authorizing"
            }
        ]}
    />;
}

function App() {
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const userAlias = useSelector(selectUserAlias);
    React.useEffect(() => {
        preLoad(dispatch).catch(e => setError(e));
    }, [dispatch])

    if (error) {
        console.error(error);
    }
    console.log("Welcome " + userAlias)

    return (
        userAlias ?
        <Landing />
        :
        <AuthLoadingBar />
        )
}

export default App;
