import React, { useState } from "react";
import {
  Modal,
  Header,
  Button,
  SpaceBetween,
  Select,
  Input,
  ColumnLayout,
  Box,
} from "@amzn/awsui-components-react";
import { ValueWithLabel } from "./util";

const TriageIssuesModal = ({ visible, setVisible, issues }) => {
  const [voteIconFilled, setVoteIconFilled] = useState(false);
  const [comment, setComment] = useState("");

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={<Header>Triage {issues.length} issue</Header>}
      size="medium"
    >
      <SpaceBetween size="m">
        <ValueWithLabel label={"Issue Status"}>
          <Select
            expandToViewport
            options={["Reviewed", "Denied", "Filed", "Not Triaged"].map(
              (opt) => {
                return { label: opt, value: opt };
              }
            )}
          ></Select>
        </ValueWithLabel>
        <ValueWithLabel label={"Comment"}>
          <Input
            value={comment}
            placeholder="Add a comment"
            onChange={({ detail }) => setComment(detail.value)}
          ></Input>
        </ValueWithLabel>
        <ValueWithLabel label={"Vote"}>
          <Button
            iconName={voteIconFilled ? "thumbs-up-filled" : "thumbs-up"}
            variant="inline-icon"
            onClick={() => setVoteIconFilled(!voteIconFilled)}
          />
        </ValueWithLabel>
        <Box textAlign="right">
          <Button variant="primary">Save</Button>
        </Box>
      </SpaceBetween>
    </Modal>
  );
};

export default TriageIssuesModal;
