import React, { useState } from "react";
import {
  Modal,
  Box,
  Header,
  Table,
  TextContent,
  Button,
  Form,
  SpaceBetween,
  FormField,
  Select,
  Input,
} from "@amzn/awsui-components-react";

const StatusLogModal = ({ visible, setVisible }) => {
  const [voteIconFilled, setVoteIconFilled] = useState(false);
  const [comment, setComment] = useState("");

  const mockData = [
    {
      userAlias: "user1",
      issueStatus: "Reviewed",
      vote: "+1",
      comment:
        "This issue needs to be addressed. Because it is a critical vulnerability",
      createdDate: "2023-05-01",
    },
    {
      userAlias: "user2",
      issueStatus: "Reviewed",
      vote: "+2",
      comment: "This issue def needs to be fixed.",
      createdDate: "2023-05-02",
    },
    {
      userAlias: "ezy",
      issueStatus: (
        <Select
          expandToViewport
          options={["Reviewed", "Denied", "Filed", "Not Triaged"].map((opt) => {
            return { label: opt, value: opt };
          })}
        ></Select>
      ),
      vote: (
        <Button
          iconName={voteIconFilled ? "thumbs-up-filled" : "thumbs-up"}
          variant="inline-icon"
          onClick={() => setVoteIconFilled(!voteIconFilled)}
        />
      ),
      comment: (
        <Input
          value={comment}
          placeholder="Add a comment"
          onChange={({ detail }) => setComment(detail.value)}
        ></Input>
      ),
      createdDate: "Will be set by the system",
    },
    // ... (other mock data objects)
  ];

  const columnDefinitions = [
    {
      id: "userAlias",
      header: "User Alias",
      cell: (item) => item.userAlias,
      width: 150,
    },
    {
      id: "issueStatus",
      header: "Issue Status",
      cell: (item) => item.issueStatus,
      width: 150,
    },
    {
      id: "vote",
      header: "Vote",
      cell: (item) => item.vote,
      width: 120,
    },
    {
      id: "comment",
      header: "Comment",
      cell: (item) => <TextContent>{item.comment}</TextContent>,
      width: 300,
    },
    {
      id: "createdDate",
      header: "Created Date",
      cell: (item) => item.createdDate,
      width: 150,
    },
  ];

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={<Header>Issue Status Change Log</Header>}
      size="max"
    >
      <SpaceBetween size="m">
        <Table
          columnDefinitions={columnDefinitions}
          items={mockData}
          wrapLines
        />

        <Form>
          <Box float="right">
            <Button variant="primary">Change Status</Button>
          </Box>
        </Form>
      </SpaceBetween>
    </Modal>
  );
};

export default StatusLogModal;
