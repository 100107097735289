import React, { useState } from "react";
import { SpaceBetween, AppLayout, Box } from "@amzn/awsui-components-react";
import Search from "../search";
import Vulnerability from "../vuln";

const Landing = () => {
  const Content = () => {
    return (
      <Box padding={"m"}>
        <SpaceBetween size="l">
          <Search />
          <Vulnerability />
        </SpaceBetween>
      </Box>
    );
  };
  return (
    <AppLayout
      navigationHide
      disableContentPaddings={true}
      content={<Content />}
    ></AppLayout>
  );
};

export default Landing;
