import React, { useState } from "react";
import {
  Table,
  Box,
  Button,
  SpaceBetween,
  StatusIndicator,
  Header,
  Popover,
  Badge,
  Link,
} from "@amzn/awsui-components-react";
import { ValueWithLabel, getReivewStatusIndicator } from "./util";
import StatusLogModal from "./statusLogModal";
import TriageIssuesModal from "./triageIssuesModal";
import FileIssuesModal from "./fileIssuesModal";
import CloseIssuesModal from "./closeIssuesModal";

const generateMockIssue = (id, hasChildren = false) => ({
  id: id.toString(),
  testSuite: `testSuite${id}`, 
  vulnType: ["CVE-XXXX-ABCD", "NPE", "OOM"][Math.floor(Math.random() * 3)],
  device: `Device${id}`,
  vulnStatus: ["Reviewed +1", "Reviewed +2", "Denied", "Filed", "Not Triaged"][
    Math.floor(Math.random() * 5)
  ],
  cvss: (Math.random() * 10).toFixed(1),
  severity: ["High", "Medium", "Low"][Math.floor(Math.random() * 3)],
  pann: ["Applicable", "Not Applicable", "-"][Math.floor(Math.random() * 3)],
  filedTicket: `DEE-4512${id}`,
  dualTicketing: [true, false][Math.floor(Math.random() * 2)],
  lastDetectedDate: "2023-07-23",
  library: `Library X-4.12${id}`,
  path: "user-build/boot.img/kernel",
  summary: `This allows attackers to ${
    [
      "bypass authentication",
      "exploit vulnerabilities",
      "gain unauthorized access",
      "perform remote code execution",
      "cause denial of service",
    ][Math.floor(Math.random() * 5)]
  }`,
  patch: [`Patch${id}`, `Patch${id + 1}`],
  children: hasChildren
    ? [generateMockIssue(id * 10 + 1), generateMockIssue(id * 10 + 2)]
    : undefined,
});

const MOCK_ISSUES = Array.from({ length: 5 }, (_, i) =>
  generateMockIssue(i + 1, true)
);

const Vulnerability = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [vulns, setVulns] = useState(MOCK_ISSUES);
  const [query, setQuery] = React.useState({
    tokens: [],
    operation: "and",
  });
  const [changeLogModalVisible, setChangeLogModalVisible] = useState(false);
  const [triageModalVisible, setTriageModalVisible] = useState(false);
  const [fileIssueModalVisible, setFileIssueModalVisible] = useState(false);
  const [closeIssueModalVisible, setCloseIssueModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleTriageIssues = () => {
    console.log("Triage selected issues:", selectedItems);
    // Implement review logic here
  };

  const handleFileIssues = () => {
    console.log("Filing selected issues:", selectedItems);
    // Implement file logic here
  };

  const loadMoreIssues = () => {
    setIsLoading(true);
    setHasError(false);
    // Simulate API call
    setTimeout(() => {
      const newIssues = Array.from({ length: 5 }, (_, i) =>
        generateMockIssue(vulns.length + i + 1, true)
      );
      setVulns([...vulns, ...newIssues]);
      setIsLoading(false);
    }, 1000);
  };

  const columnDefinitions = [
    {
      id: "testSuite",
      header: "Test Suite",
      cell: (item) => item.testSuite,
      filteringProperty: "testSuite",
    },
    {
      id: "vulnType",
      header: "Vulnerability Type",
      cell: (item) => item.vulnType,
      filteringProperty: "vulnType",
    },
    {
      id: "device",
      header: "Device",
      cell: (item) => item.device,
      filteringProperty: "device",
    },
    {
      id: "vulnStatus",
      header: "Vulnerability Status",
      cell: (item) => (
        <Button onClick={() => setChangeLogModalVisible(true)}>
          {getReivewStatusIndicator(item.vulnStatus)}
        </Button>
      ),
      filteringProperty: "vulnStatus",
    },
    {
      id: "cvss",
      header: "CVSS V4",
      cell: (item) => item.cvss,
      filteringProperty: "cvss",
    },
    {
      id: "severity",
      header: "Severity",
      cell: (item) => (
        <Popover
          triggerType="text"
          size="large"
          content={
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {JSON.stringify(
                {
                  cveId: "CVE-2022-1245",
                  summary:
                    "A privilege escalation flaw was found in the token exchange feature of keycloak. Missing authorization allows a client application holding a valid access token to exchange tokens for any target client by passing the client_id of the target. This could allow a client to gain unauthorized access to additional services.",
                  highestSeverityGroupLabel: "CRITICAL",
                  cveKeywordLabels: ["ELEVATION_OF_PRIVILEGE"],
                  matchedStrings: ["privilege escalat"],
                  severityGroupLabels: ["CRITICAL"],
                  severityScore: 500,
                  timestamp: "2024-07-24 22:52:03.714",
                  adjustmentsRecommended: {},
                },
                null,
                2
              )}
            </pre>
          }
        >
          <StatusIndicator
            type={
              item.severity === "High"
                ? "error"
                : item.severity === "Medium"
                ? "warning"
                : "info"
            }
          >
            {item.severity}
          </StatusIndicator>
        </Popover>
      ),
      filteringProperty: "severity",
    },
    {
      id: "pann",
      header: "PANN",
      cell: (item) => item.pann,
      filteringProperty: "pann",
    },
    {
      id: "path",
      header: "Path",
      cell: (item) => item.path,
      filteringProperty: "path,",
    },
    {
      id: "library",
      header: "Library",
      cell: (item) => item.library,
      filteringProperty: "library",
    },
    {
      id: "summary",
      header: "Summary",
      cell: (item) => item.summary,
      filteringProperty: "summary",
    },
    {
      id: "filedTicket",
      header: "Filed Ticket",
      cell: (item) => (
        <Popover
          content={
            <SpaceBetween direction="horizontal" size="m">
              <ValueWithLabel label={"Type"}>
                {["Jira", "Shepherd"][Math.floor(Math.random() * 2)]}
              </ValueWithLabel>
              <ValueWithLabel label={"Link"}>
                <Link href="">{item.filedTicket}</Link>
              </ValueWithLabel>
              <ValueWithLabel label={"Created Date"}>
                {new Date().toISOString()}
              </ValueWithLabel>
            </SpaceBetween>
          }
        >
          {item.filedTicket}
        </Popover>
      ),
      filteringProperty: "filedTicket",
    },
    {
      id: "lastDetectedDate",
      header: "Last Detected Date",
      cell: (item) => (
        <Popover
          triggerType="text"
          size="large"
          content={
            <Box>
              <b>Detected In the Lastest Build</b>
              <br /> True <br />
              <br />
              <b>Last Detected Build</b>
              <br /> ImageX.BuildA <br />
              <br />
              <b>Detection History</b>
              <SpaceBetween size="xxs">
                <Box>
                  <Badge color="red">ImageX.BuildA</Badge> 2023-07-23
                </Box>
                <Box>
                  <Badge color="red">ImageX.BuildZ</Badge> 2023-07-16
                </Box>
                <Box>
                  <Badge color="red">ImageX.BuildY</Badge> 2023-07-09
                </Box>
                <Box>
                  <Badge color="green">ImageX.BuildX</Badge> 2023-07-02
                </Box>
              </SpaceBetween>
            </Box>
          }
        >
          {item.lastDetectedDate}
        </Popover>
      ),
      filteringProperty: "lastDetectedDate",
    },

    {
      id: "patch",
      header: "Patch",
      cell: (item) => (
        <Popover
          content={item.patch.map((p) => (
            <Box>
              <Link href={p}>{p}</Link>
            </Box>
          ))}
        >
          {item.patch.length}-patch
        </Popover>
      ),
      filteringProperty: "patch",
    },
  ];

  const filteringOptions = [
    {
      propertyKey: "testSuite",
    },
    {
      propertyKey: "issueStatus",
      value: "Reviewed",
    },
    {
      propertyKey: "issueStatus",
      value: "Ready To Review",
    },
    {
      propertyKey: "severity",
      value: "Critical",
    },
    {
      propertyKey: "severity",
      value: "Major",
    },
    {
      propertyKey: "severity",
      value: "Minor",
    },
    {
      propertyKey: "severity",
      value: "Undefined",
    },
    {
      propertyKey: "pann",
      groupValuesLabel: "PANN",
      operators: [":", "!:"],
    },
    {
      propertyKey: "filedIssue",
      groupValuesLabel: "Filed Issue",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyKey: "lastDetectedDate",
      groupValuesLabel: "Last Detected Date",
      operators: [":", "!:", "=", "!=", "<", "<=", ">", ">="],
    },
    {
      propertyKey: "library",
      groupValuesLabel: "Library",
      operators: [":", "!:"],
    },
    {
      propertyKey: "summary",
      groupValuesLabel: "Summary",
      operators: [":", "!:"],
    },
    {
      propertyKey: "patches",
      groupValuesLabel: "Patches",
      operators: [":", "!:"],
    },
  ];

  const filteringProperties = [
    {
      key: "testSuite",
      propertyLabel: "Test Suite",
      groupValuesLabel: "Test Suite",
      operators: [":", "!:"],
    },
    {
      key: "issueStatus",
      propertyLabel: "Issue Status",
      groupValuesLabel: "Issue Status",
      operators: [":", "!:"],
    },
    {
      key: "severity",
      propertyLabel: "Severity",
      groupValuesLabel: "Severity",
      operators: [":", "!:"],
    },
    {
      key: "pann",
      propertyLabel: "PANN",
      groupValuesLabel: "PANN",
      operators: [":", "!:"],
    },
    {
      key: "filedIssue",
      propertyLabel: "Filed Issue",
      groupValuesLabel: "Filed Issue",
      operators: [":", "!:", "=", "!="],
    },
    {
      key: "lastDetectedDate",
      propertyLabel: "Last Detected Date",
      groupValuesLabel: "Last Detected Date",
      operators: [":", "!:", "=", "!=", "<", "<=", ">", ">="],
    },
    {
      key: "library",
      propertyLabel: "Library",
      groupValuesLabel: "Library",
      operators: [":", "!:"],
    },
    {
      key: "summary",
      propertyLabel: "Summary",
      groupValuesLabel: "Summary",
      operators: [":", "!:"],
    },
    {
      key: "patches",
      propertyLabel: "Patches",
      groupValuesLabel: "Patches",
      operators: [":", "!:"],
    },
  ];

  return (
    <Box>
      <Table
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => setTriageModalVisible(true)}
                  disabled={selectedItems.length === 0}
                >
                  Triage {selectedItems.length} Issue
                </Button>
                <Button
                  onClick={() => setCloseIssueModalVisible(true)}
                  disabled={selectedItems.length === 0}
                >
                  Close {selectedItems.length} Issue
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setFileIssueModalVisible(true)}
                  disabled={selectedItems.length === 0}
                >
                  File {selectedItems.length} Issue
                </Button>
              </SpaceBetween>
            }
          >
            Vulnerabilities ({vulns.length}/741)
          </Header>
        }
        stickyHeader
        columnDefinitions={columnDefinitions}
        items={vulns}
        selectionType="multi"
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        loadingText="Loading vulnerability"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No vulnerability</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No vulnerability to display.
            </Box>
          </Box>
        }
        renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
          `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
        }
        renderLoaderPending={() => (
          <Button
            variant="inline-link"
            iconName="add-plus"
            onClick={loadMoreIssues}
          >
            Show more
          </Button>
        )}
        renderLoaderLoading={() => (
          <StatusIndicator type="loading">Loading items</StatusIndicator>
        )}
        renderLoaderError={() => (
          <StatusIndicator type="error">Loading error</StatusIndicator>
        )}
        getLoadingStatus={(item) => "pending"}
        trackBy={"id"}
      />
      <StatusLogModal
        visible={changeLogModalVisible}
        setVisible={setChangeLogModalVisible}
      />
      <TriageIssuesModal
        visible={triageModalVisible}
        setVisible={setTriageModalVisible}
        issues={selectedItems}
      />
      <FileIssuesModal
        visible={fileIssueModalVisible}
        setVisible={setFileIssueModalVisible}
        issues={selectedItems}
      />
      <CloseIssuesModal
        visible={closeIssueModalVisible}
        setVisible={setCloseIssueModalVisible}
        issues={selectedItems}
      />
    </Box>
  );
};

export default Vulnerability;
