import React, { useState } from 'react';
import {
    Container,
    Header,
    SpaceBetween,
    FormField,
    Multiselect,
    DatePicker,
    Button,
    Grid,
    Box,
    Input,
    ColumnLayout,
    CopyToClipboard,
    Hotspot,
    Popover,
    Icon,
} from '@amzn/awsui-components-react';

const Search = () => {
    const [vulnerabilityTypes, setVulnerabilityTypes] = useState([
      { label: "CVE-XXXX-ABCD", value: "CVE-XXXX-ABCD" },
      { label: "NPE", value: "NPE" },
    ]);
    const [devices, setDevices] = useState([
        { label: 'device1', value: '1' },
        { label: 'device2', value: '2' },
    ]);
    const [lastDetectedDateSince, setLastDetectedDateSince] = useState();
    const [testSuites, setTestSuites] = useState([
        { label: 'suite1', value: '1' },
        { label: 'suite2', value: '2' },
    ]);
    const [libraries, setLibraries] = useState([
        { label: 'lib1', value: '1' },
        { label: 'lib2', value: '2' },
    ]);
    const [cvssStart, setCvssStart] = useState("");
    const [cvssEnd, setCvssEnd] = useState("");

    const [severity, setSeverity] = useState([]);
    const [ticketIds, setTicketIds] = useState([
        { label: 'ticketId1', value: '1' },
        { label: 'ticketId2', value: '2' },
    ]);
    const [path, setPath] = useState("");
    const [pann, setPann] = useState([]);
    const [summary, setSummary] = useState("");
    const [vulnStatus, setVulnStatus] = useState([]);

    const handleSearch = () => {
        console.log({
            devices: devices,
            newIssueDate: lastDetectedDateSince,
            testSuites,
            libraries,
            severity,
            ticketIds,
            pann,
            vulnStatus,
        });
    };

    return (
      <Box
        header={
          <Header
            variant="h1"
            description="Search and filter issues across different builds and devices"
          >
            Issue Triage Search
          </Header>
        }
      >
        <SpaceBetween size="l">
          <ColumnLayout columns={4}>
            <FormField label="Test Suite">
              <Multiselect
                selectedOptions={testSuites}
                onChange={({ detail }) => setTestSuites(detail.selectedOptions)}
                options={[
                  { label: "suite1", value: "1" },
                  { label: "suite2", value: "2" },
                ]}
                placeholder="Select test suites"
              />
            </FormField>
            <FormField label="Vulnerability Type">
              <Multiselect
                selectedOptions={vulnerabilityTypes}
                onChange={({ detail }) =>
                  setVulnerabilityTypes(detail.selectedOptions)
                }
                options={[
                  { label: "CVE-XXXX-ABCD", value: "CVE-XXXX-ABCD" },
                  { label: "NPE", value: "NPE" },
                ]}
                placeholder="Select vulnerability types"
              />
            </FormField>
            <FormField label="Devices">
              <Multiselect
                selectedOptions={devices}
                onChange={({ detail }) => setDevices(detail.selectedOptions)}
                options={[
                  { label: "image1", value: "1" },
                  { label: "image2", value: "2" },
                ]}
                placeholder="Select devices"
              />
            </FormField>
            <FormField label="Vulnerability Status">
              <Multiselect
                selectedOptions={vulnStatus}
                onChange={({ detail }) => setVulnStatus(detail.selectedOptions)}
                options={[
                  { label: "Not Reviewed", value: "not_reviewed" },
                  { label: "Ready To File", value: "ready_to_file" },
                ]}
                placeholder="Select vulnerability status"
              />
            </FormField>
            <FormField label="CVSS V4 Range">
              <SpaceBetween direction="horizontal" size="xs">
                <Input
                  type="decimal"
                  step={0.01}
                  value={cvssStart}
                  onChange={({ detail }) =>
                    /^(?:10(?:\.0?)?|[0-9](?:\.[0-9]?)?|)$/.test(
                      detail.value
                    ) && setCvssStart(detail.value)
                  }
                  onBlur={() =>
                    cvssStart.endsWith(".") && setCvssStart(cvssStart + "0")
                  }
                />
                To
                <Input
                  type="decimal"
                  step={0.01}
                  value={cvssEnd}
                  onChange={({ detail }) =>
                    /^(?:10(?:\.0?)?|[0-9](?:\.[0-9]?)?|)$/.test(
                      detail.value
                    ) && setCvssEnd(detail.value)
                  }
                  onBlur={() =>
                    cvssEnd.endsWith(".") && setCvssEnd(cvssEnd + "0")
                  }
                />
              </SpaceBetween>
            </FormField>
            <FormField label="Severity">
              <Multiselect
                selectedOptions={severity}
                onChange={({ detail }) => setSeverity(detail.selectedOptions)}
                options={[
                  { label: "Critical", value: "critical" },
                  { label: "High", value: "high" },
                  { label: "Medium", value: "medium" },
                  { label: "Low", value: "low" },
                ]}
                placeholder="Select severity"
              />
            </FormField>
            <FormField label="PANN">
              <Multiselect
                selectedOptions={pann}
                onChange={({ detail }) => setPann(detail.selectedOptions)}
                options={[
                  { label: "Applicable", value: "applicable" },
                  { label: "Not Applicable", value: "not_applicable" },
                ]}
                placeholder="Select PANN"
              />
            </FormField>
            <FormField label="Path">
              <Input
                placeholder="Enter Path"
                onChange={({ detail }) => setPath(detail.value)}
                value={path}
              />
            </FormField>

            <FormField label="Library">
              <Multiselect
                selectedOptions={libraries}
                onChange={({ detail }) => setLibraries(detail.selectedOptions)}
                options={[
                  { label: "lib1", value: "1" },
                  { label: "lib2", value: "2" },
                ]}
                placeholder="Select libraries"
              />
            </FormField>
            <FormField label="Summary">
              <Input
                placeholder="Enter Summary"
                onChange={({ detail }) => setSummary(detail.value)}
                value={summary}
              />
            </FormField>
            <FormField
              label={
                <SpaceBetween direction="horizontal" size="xxs">
                  <Box>Ticket ID</Box>
                  <Popover
                    size="medium"
                    triggerType="custom"
                    content={
                      <Box>
                        Search by Jira/Shepherd tickets. <br /> To search Jira
                        tickets in a specific server. Prefix the '/' and server
                        name before the ticket ID. Ex: labcollab/PROJVEGA-12345
                      </Box>
                    }
                  >
                    <Button variant="inline-icon" iconName="status-info" />
                  </Popover>
                </SpaceBetween>
              }
            >
              <Multiselect
                selectedOptions={ticketIds}
                onChange={({ detail }) => setTicketIds(detail.selectedOptions)}
                options={[
                  { label: "ticketId1", value: "1" },
                  { label: "ticketId2", value: "2" },
                ]}
                placeholder="Select ticket IDs"
              />
            </FormField>
            <FormField label="Last Detected Date From">
              <DatePicker
                onChange={({ detail }) =>
                  setLastDetectedDateSince(detail.value)
                }
                value={lastDetectedDateSince}
                placeholder="Select date"
              />
            </FormField>
          </ColumnLayout>
          <Box textAlign="center">
            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>{" "}
            {"  "}
            <CopyToClipboard
              copyButtonText="Copy Query"
              copyErrorText="Query failed to copy"
              copySuccessText="Query copied"
              textToCopy=""
            />
          </Box>
        </SpaceBetween>
      </Box>
    );
};

export default Search;