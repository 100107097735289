import React, { useState } from "react";
import {
  Modal,
  Header,
  Button,
  SpaceBetween,
  Input,
  Box,
  ColumnLayout,
  Container,
  TokenGroup,
  Textarea,
  Multiselect,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { ValueWithLabel, getReivewStatusIndicator } from "./util";

const FileIssuesModal = ({ visible, setVisible, issues }) => {
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={<Header variant="h1">File {issues.length} issue</Header>}
      size="max"
    >
      <SpaceBetween size="l">
        <Container
          header={<Header variant="h2">Shepherd Configuration</Header>}
        >
          <ColumnLayout columns={2}>
            <ValueWithLabel label={"Owning Bindle"}>
              <Input value={"amzn1.bindle.resource.aaaaaaaaaaaa"}></Input>
            </ValueWithLabel>
            <ValueWithLabel label={"Scanner Name"}>
              <Input disabled value={"Black Duck Binary Analyzer"}></Input>
            </ValueWithLabel>
          </ColumnLayout>
          <br />
          <br />
          <SpaceBetween size="m">
            {issues.map((issue) => (
              <Container key={issue.id}
                header={
                  <Header variant="h3">
                    {issue.device}:{issue.path}:{issue.vulnType}{" "}
                    {getReivewStatusIndicator(issue.vulnStatus)}{" "}
                    {
                      <StatusIndicator
                        type={issue.dualTicketing ? "info" : "warning"}
                      >
                        {issue.dualTicketing
                          ? "Will file Jira"
                          : "Will NOT file Jira"}
                      </StatusIndicator>
                    }
                  </Header>
                }
              >
                <ColumnLayout columns={2}>
                  <ValueWithLabel label={"Title"}>
                    <Textarea value={issue.summary} />
                  </ValueWithLabel>
                  <ValueWithLabel label={"Describtion"}>
                    <Textarea value={issue.describtion || ""} />
                  </ValueWithLabel>
                  <ValueWithLabel label={"Threat"}>
                    <Textarea value={issue.threat || ""} />
                  </ValueWithLabel>
                  <ValueWithLabel label={"Remediation"}>
                    <Textarea value={issue.patch.join(";") || ""} />
                  </ValueWithLabel>
                  <ValueWithLabel label={"Severity"}>
                    <Textarea value={issue.severity || ""} />
                  </ValueWithLabel>
                  <ValueWithLabel label={"Last Detected Date"}>
                    <Input disabled value={issue.lastDetectedDate} />
                  </ValueWithLabel>
                </ColumnLayout>
              </Container>
            ))}
          </SpaceBetween>
        </Container>
        <Container header={<Header variant="h2">Jira Configuration</Header>}>
          <ColumnLayout columns={2}>
            <ValueWithLabel label={"Project"}>
              <Input value={"PROJVEGA"}></Input>
            </ValueWithLabel>
            <ValueWithLabel label={"Components"}>
              <Multiselect options={[]}></Multiselect>
              <TokenGroup
                items={[{ label: "Security" }, { label: "Severity" }]}
              />
            </ValueWithLabel>
            <ValueWithLabel label={"Affected Devices"}>
              <Multiselect options={[]}></Multiselect>
              <TokenGroup
                items={[
                  { label: "Device 1" },
                  { label: "Device 2" },
                  { label: "Device 3" },
                ]}
              />
            </ValueWithLabel>
            <ValueWithLabel label={"Labels"}>
              <Multiselect options={[]}></Multiselect>
              <TokenGroup
                items={[
                  { label: "PANN_Applicable" },
                  { label: "DVDT-Security" },
                  { label: "OSS Security" },
                ]}
              />
            </ValueWithLabel>
            <ValueWithLabel label={"Summary"}>
              <Input value={""}></Input>
            </ValueWithLabel>
            <ValueWithLabel label={"Description"}>
              <Textarea value={""} />
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
        <Box textAlign="right">
          <Button variant="primary">File {issues.length} issue</Button>
        </Box>
      </SpaceBetween>
    </Modal>
  );
};

export default FileIssuesModal;
